<template>
  <b-modal
    :id="id || 'modal-closed-shop-info'"
    title="Keterangan Tutup Toko"
    centered
    hide-header-close
    hide-footer
  >
    {{ notes }}
  </b-modal>
</template>

<script>
export default {
  name: "ModalClosedShopInfo",
  props: ["id", "notes"],
};
</script>
